<template>
	<div class="flex flex-col space-y-4 lg:w-72">
		<h3 class="flex items-start text-xl font-bold h-7">
			<img
				src="@/assets/icons/about.svg"
				class="w-6 h-6 mt-1 mr-2"
				alt="About Icon"
			>
			About
		</h3>
		<WwReadMore
			v-if="about.length"
			:max-height="120"
			keep-trigger-on-bottom
			@is-expanded="setExpanded"
		>
			<template #default="{ hideTrigger }">
				<WwHtmlContent
					class="text-base text-left"
					:markup="about"
					:expanded="expanded"
					use-line-clamp-four
					@hide-trigger="hideTrigger"
				/>
			</template>
		</WwReadMore>
		<p
			v-else
			class="flex items-center w-full italic text-gray-500 h-7"
		>
			No description provided.
		</p>
	</div>
</template>

<script async>
import WwHtmlContent from '@/components/UI/WwHtmlContent.vue'
import WwReadMore from '@/components/UI/WwReadMore.vue'

export default {
	components: {
		WwReadMore,
		WwHtmlContent
	},
	props: {
		about: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			expanded: false
		}
	},
	methods: {
		setExpanded(isExpanded) {
			this.expanded = isExpanded
		}
	}
}
</script>
