<template>
	<WwModal
		:modal-id="REPORT_BUSINESS_MODAL"
		title="Report Business"
		:header-offset="235"
		ok-title="Submit"
		:ok-color="reportIsValid ? 'green' : 'red'"
		ok-text-color="text-white"
		:ok-disabled="!reportIsValid"
		@ok="handleSubmit"
	>
		<template #body>
			<div class="flex flex-col w-full mb-4">
				<label for="report-reason">
					Reason for reporting
				</label>
				<select
					v-model="report.reason"
					name="report-reason"
					class="p-1 border border-gray-300 rounded focus:ring focus:ring-green-300"
				>
					<option
						disabled
						value=""
					>
						Choose a reason
					</option>
					<option
						v-for="reason in reasons"
						:key="reason"
						:value="reason"
					>
						{{ reason }}
					</option>
				</select>
			</div>
			<div class="w-full mb-4">
				<WwTextInput
					id="report-email"
					v-model="report.email"
					name="report-email"
					placeholder="Enter email address"
					label="Your Email"
					input-class="w-full"
					:input-type="EMAIL"
					required
					:state="inputFieldState(report.email, EMAIL)"
				/>
			</div>
			<div class="w-full">
				<WwTextInput
					id="report-text"
					v-model="report.report"
					name="Report Text Area"
					:placeholder="placeholderText"
					label="Tell us more"
					input-class="w-full h-24"
					:input-type="TEXTAREA"
					required
					:state="inputFieldState(report.report, TEXTAREA)"
				/>
				<div class="text-sm italic">
					Submitted information is kept private
				</div>
			</div>
		</template>
	</Wwmodal>
</template>

<script async>
import { mapMutations } from 'vuex'

import WwModal from '@/components/UI/WwModal.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { REPORT_BUSINESS_MODAL } from '@/constants/modal/names.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEXTAREA } from '@/constants/text-inputs/types.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		WwModal,
		WwTextInput
	},
	data() {
		return {
			reasons: [
				'Incorrect information',
				'Missing information',
				'Fraudulent business'
			],
			report: {
				reason: '',
				email: '',
				report: ''
			},
			REPORT_BUSINESS_MODAL,
			INPUT_STATES,
			TEXTAREA,
			EMAIL,
			inputFieldState
		}
	},
	computed: {
		reportIsValid() {
			return !!this.report?.reason.length &&
				!!this.report?.report.length
		},
		placeholderText() {
			return "Tell us what's wrong with this listing and we'll remove it or take appropriate actions as soon as we can verify the information."
		}
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		handleClose(event) {
			event.stopPropagation()
			this.closeModal()
		},
		handleSubmit() {
			this.$emit('report-submitted', this.report)
			this.report.reason = ''
			this.report.email = ''
			this.report.report = ''
		}
	}
}
</script>
