<template>
	<div class="flex flex-col pb-4 space-y-4 lg:pb-0">
		<h3 class="flex text-xl font-bold h-7">
			<img
				src="@/assets/icons/findus.svg"
				class="h-6 w-6 mr-2 mt-0.5"
				alt="Find Us icon"
			>
			Find Us
		</h3>
		<ul
			v-if="hasLinks"
			class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-1 gap-y-2"
		>
			<li
				v-for="(social, index) in newLinks"
				:key="index"
			>
				<a
					v-if="social.name === 'Website'"
					v-track-event:external.click="externalLinkClickTrackingData"
					class="flex transition-all duration-100 ease-in-out rounded-md lg:hover:bg-gray-200"
					:href="social.url"
					target="_blank"
				>
					<img
						:src="social.icon"
						:alt="social.alt"
						class="w-6 h-6 mr-2"
					>
					<span>
						{{ social.name }}
					</span>
				</a>
				<a
					v-else
					class="flex transition-all duration-100 ease-in-out rounded-md lg:hover:bg-gray-200"
					:href="social.url"
				>
					<img
						:src="social.icon"
						:alt="social.alt"
						class="w-6 h-6 mr-2"
					>
					<span>
						{{ social.name }}
					</span>
				</a>
			</li>
		</ul>
		<div v-else>
			<p class="flex items-center w-full italic text-gray-500 h-7">
				No socials linked.
			</p>
		</div>
	</div>
</template>

<script async>
import PLATFORMS from '@/constants/business/social-media.js'

export default {
	props: {
		listingId: {
			type: Number,
			required: true
		},
		links: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			PLATFORMS,
			website: {
				name: 'Website',
				icon: require('@/assets/icons/website.svg'),
				alt: 'Website Icon - Where\'s Weed'
			}
		}
	},
	computed: {
		websiteUrlAlreadyFormatted() { // #NOTE: apparently api gives many different formats for website url, and not all work...
			return this.links?.website.slice(0, 4) === 'http' || this.links?.website.slice(0, 2) === '//'
		},
		websiteUrl() {
			if (this.links?.website) {
				if (this.websiteUrlAlreadyFormatted) {
					return this.links.website
				} else {
					return `//${this.links.website}`
				}
			}
			return ''
		},
		externalLinkClickTrackingData() {
			return {
				listingId: this.listingId,
				destinationUrl: this.websiteUrl
			}
		},
		hasLinks() {
			return this.newLinks.length
		},
		showWebsite() {
			return this.websiteUrl.length
		},
		newLinks() {
			const socials = this.links.socials || {}
			const socialLinks = Object.keys(socials).filter(key =>
				typeof socials[key] === 'string' &&
				socials[key].length &&
				key !== '__typename'
			).map(key => {
				return {
					name: this.PLATFORMS[key].name,
					alt: this.PLATFORMS[key].alt,
					url: this.PLATFORMS[key].url + socials[key],
					icon: this.PLATFORMS[key].icon
				}
			})
			if (this.showWebsite) {
				const key = 'website'
				socialLinks.unshift({
					url: this.websiteUrl,
					icon: this[key].icon,
					name: this[key].name,
					alt: this[key].alt
				})
			}
			return socialLinks
		}
	}
}
</script>
