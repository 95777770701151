<template>
	<div
		v-if="businessId"
		class="flex flex-col w-full space-y-8 border-y lg:space-y-0 lg:pb-4 lg:flex-row lg:justify-between"
	>
		<div class="w-full lg:w-72">
			<div
				v-if="hasMap && !directionsUrl"
				class="block pb-1"
			>
				<img
					:src="mapImageUrl"
					width="1000"
					height="250"
					alt="Map"
					class="w-full"
				>
			</div>
			<a
				v-else-if="hasMap"
				:href="directionsUrl"
				target="_blank"
				class="block pb-1"
			>
				<img
					:src="mapImageUrl"
					width="1000"
					height="250"
					alt="Map"
					class="w-full"
				>
			</a>
			<div>
				<div class="py-4 min-h-7">
					{{ street }}<br>
					{{ cityStateZip }}
				</div>
				<div class="flex justify-start max-w-sm space-x-2">
					<button
						type="button"
						class="w-1/2 px-4 py-2 mt-3 text-base font-medium border border-gray-300 rounded-md shadow-sm hover:shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm"
						@click="showModal(REPORT_BUSINESS_MODAL)"
					>
						Report
					</button>
					<button
						v-if="!isClaimed"
						class="w-1/2 px-4 py-2 mt-3 text-base font-medium border border-gray-300 rounded-md shadow-sm hover:shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm"
						@click="handleClaimBusiness"
					>
						Claim Business
					</button>
				</div>
			</div>
		</div>
		<BusinessAmenities :settings="settings" />
		<BusinessAboutDescription :about="about" />
		<BusinessLinks
			:links="links"
			:listing-id="businessId"
		/>
		<ReportBusinessModal
			:modal-id="REPORT_BUSINESS_MODAL"
			@report-submitted="handleReportSubmitted"
		/>
	</div>
</template>
<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import BusinessAboutDescription from '@/components/business/about/BusinessAboutDescription.vue'
import BusinessAmenities from '@/components/business/about/BusinessAmenities.vue'
import BusinessLinks from '@/components/business/about/BusinessLinks.vue'
import ReportBusinessModal from '@/components/UI/modals/ReportBusinessModal.vue'
import { REPORT_BUSINESS_MODAL } from '@/constants/modal/names.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import { ListingAboutFragment, ListingGeneralDetailsFragment } from '@/gql/fragments/listing.gql'
import { logError } from '@/utils/error-handling.js'
import { formatAddress } from '@/utils/formatText.js'

export default {
	components: {
		ReportBusinessModal,
		BusinessAboutDescription,
		BusinessAmenities,
		BusinessLinks
	},
	data() {
		return {
			REPORT_BUSINESS_MODAL
		}
	},
	computed: {
		...mapGetters('business', [ 'businessId' ]),
		about() {
			return this.aboutPageData?.about || ''
		},
		settings() {
			return { ...this.aboutPageData?.settings, ...this.listingData.settings } || {}
		},
		isOpen() {
			return !!this.listingData?.isOpen
		},
		links() {
			return {
				socials: this.aboutPageData?.socialMedia,
				website: this.aboutPageData?.website || ''
			}
		},
		listingId() {
			return this.aboutPageData?.id || ''
		},
		directionsUrl() {
			return this.listingData?.directionsLink
		},
		businessType() {
			return this.listingData?.businessType
		},
		mapImageUrl() {
			const staticMapUrl = this.aboutPageData?.staticMap
			const staticMapResize = staticMapUrl?.replace('&scale=2', '&scale=1')
			return staticMapResize
		},
		hasMap() {
			return !!this.aboutPageData?.staticMap.length
		},
		street() {
			return this.listingData?.address?.street || ''
		},
		cityStateZip() {
			if (this.listingData?.address) {
				const {
					city, state, zip
				} = this.listingData?.address
				if (state.short === 'DC') {
					return formatAddress({
						city, zip
					})
				} else {
					return formatAddress({
						city, state: state.short, zip
					})
				}
			} else {
				return ''
			}
		},
		isClaimed() {
			return this.listingData?.creatorId > 0
		},
		aboutPageData() {
			return this.$apollo.provider.defaultClient.readFragment({
				fragment: ListingAboutFragment,
				id: `Listing:${this.businessId}`
			})
		},
		listingData() {
			return this.$apollo.provider.defaultClient.readFragment({
				fragment: ListingGeneralDetailsFragment,
				id: `Listing:${this.businessId}`
			})
		}
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		...mapActions('business', [ 'reportBusiness' ]),
		...mapMutations('toast', [ 'showToast' ]),
		handleClaimBusiness() {
			const {
				street, street2, city, zip, state
			} = this.listingData?.address
			const {
				website
			} = this.aboutPageData
			const {
				businessType, name, phone
			} = this.listingData

			localStorage.setItem('businessPrefillData', JSON.stringify({
				type: businessType,
				name,
				address: street,
				address2: street2,
				city,
				state: state?.short || '',
				zip,
				website,
				phone,
				email: this.email
			}))
			this.$router.push(`/tour/${this.businessType}`)
		},
		reportIsValid(report) {
			return report?.email.length &&
				report?.reason.length &&
				report?.report.length
		},
		async handleReportSubmitted(report) {
			try {
				if (!this.reportIsValid(report)) {
					throw new Error('Report is missing information')
				}
				const { data } = await this.reportBusiness({
					...report,
					business: this.aboutPageData.id
				})
				if (!data?.success) {
					throw new Error('There was a problem. Please try again.')
				}
				if (data && data.success) {
					this.showToast({
						primaryText: 'Report Submission Successful',
						secondaryText: 'This business has been reported. Thank you for your assistance.',
						type: SUCCESS
					})
				}
			} catch (error) {
				this.showToast({
					primaryText: 'Server Error',
					secondaryText: 'There was a problem submitting your report, please try again',
					type: ERROR
				})
				logError(error)
			}
		}
	}
}
</script>
