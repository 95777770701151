<template>
	<div class="flex flex-col space-y-4 min-h-40">
		<h3 class="flex text-xl font-bold h-7">
			<img
				src="@/assets/icons/amenities.svg"
				class="h-6 w-6 mr-2 mt-0.5"
				alt="Amenities Icon"
			>
			Amenities
		</h3>
		<ul
			v-if="hasAmenities"
			class="ml-4 list-disc"
		>
			<li
				v-for="(feature, index) in amenities"
				:key="index"
			>
				{{ getFeatureText(feature) }}
			</li>
		</ul>
		<div v-else>
			<p class="flex items-center w-full italic text-gray-500 h-7">
				No amenities listed.
			</p>
		</div>
	</div>
</template>

<script async>
export default {
	props: {
		settings: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			amenitiesList: {
				acceptsCreditCards: 'Accepts Credit Cards',
				ageRequirement: 'Age Requirement',
				deliveryMinimum: 'Delivery Minimum: $',
				firstTimePatientMessage: 'First Time Patient: ',
				hasAtm: 'On-Site ATM',
				hasCurbsidePickup: 'Curbside Pickup',
				hasDelivery: 'Delivery Available',
				hasGuestCheckout: 'Guest Checkout',
				hasMedical: 'Medical',
				hasOrdering: 'Online Ordering',
				hasPickup: 'In-Store Pickup',
				hasRecreational: 'Recreational',
				hasTerminalPatientDiscount: 'Terminal Patient Discount',
				hasVeteranDiscount: 'Discount For Veterans',
				requireIdVerification: 'Valid ID Required',
				requirePhoneVerification: 'Phone Verification Required',
				wheelchairAccessible: 'Wheelchair Accessible',
				hasSecurity: 'Security'
			}
		}
	},
	computed: {
		hasAmenities() {
			return !!this.amenities.length
		},
		deliveryMinimum() {
			return this.settings?.deliveryMinimum || 0
		},
		amenities() {
			return Object.keys(this.settings).filter(key =>
				this.settings[key] === true ||
				typeof this.settings[key] === 'string' && this.settings[key].length && key !== '__typename' ||
				typeof this.settings[key] === 'number' && this.settings[key]
			).map(key =>
				this.amenitiesList[key]
			)
		}
	},
	methods: {
		getFeatureText(feature) {
			if (feature === 'Delivery Minimum: $') {
				return feature + this.deliveryMinimum
			} else if (feature === 'First Time Patient: ') {
				return feature + this.settings.firstTimePatientMessage
			} else {
				return feature
			}
		}
	}
}
</script>
